import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import { RootState } from '../../../../store/store';
import { getLineItemSelector } from '@modules/subscriptions/core/selectors';
import { getUpdatedSubscriptionOptions } from '@modules/settings/core/selectors';

const getPending = (state: RootState) => state.products.pending;

const getProducts = (state: RootState) => state.products.data;

const getError = (state: RootState) => state.products.error;

export const getProductsSelector = createSelector(getProducts, (products) => products);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getProductSelector = createSelector(getProducts, (products) => products.product);

export const getProductOptionsSelector = createSelector(
  getProducts,
  (products) => products.product.options,
);

export const getVariantsSelector = createSelector(
  getProducts,
  (products) => products?.product?.variants || [],
);

export const getVariantSelector = createSelector(
  [getVariantsSelector, getLineItemSelector],
  (variants, lineItem) =>
    variants?.find((variant: any) => parseInt(variant.id) === lineItem?.variantShopifyId),
);

export const getVariantProductInfoSelector = createSelector(
  [getVariantSelector],
  (variant) => variant?.metafields?.product_info ?? null,
);

export const getUpdatedVariantSelector = createSelector(
  [getUpdatedSubscriptionOptions, getVariantsSelector],
  (updatedSubscriptionOptions, variants) =>
    variants.find(
      (variant: any) =>
        updatedSubscriptionOptions?.cycle === variant.selectedOptions?.cycle &&
        updatedSubscriptionOptions?.pension === variant.selectedOptions?.pension &&
        isEqual(updatedSubscriptionOptions?.recipes, variant.selectedOptions.recipes),
    ),
);

export const getIsDisabled14DaysCycleSelector = createSelector(
  [getVariantsSelector],
  (variants) => !variants.some((variant: any) => variant.selectedOptions.cycle === 14),
);

export const getIsDisabledHalfPensionSelector = createSelector(
  [getVariantsSelector],
  (variants) => {
    if (!Array.isArray(variants)) return true;

    return !variants.some((variant: any) => variant?.selectedOptions?.pension === 'H');
  }
);

export const getDisabledRecipesSelector = createSelector([getVariantsSelector], (variants) => {});
