import { all, takeLatest, put, delay } from 'redux-saga/effects';
import { fetchOrderHistoryRequest } from '@modules/orders/core/actions';
import { PlaceOrderSuccess } from '@modules/subscriptions/core/types';
import { fetchOrderScheduleRequest } from '@modules/order-schedule/core/actions';

function* watchPlaceOrderSuccess(action: PlaceOrderSuccess) {
  yield delay(3000);

  const subscriptionId = action.payload.subscription.id;

  yield put(fetchOrderScheduleRequest({ subscriptionId }));
  yield put(fetchOrderHistoryRequest({ subscriptionId }));
}

export function* rootWatcher() {
  yield all([takeLatest('PLACE_ORDER_SUCCESS', watchPlaceOrderSuccess)]);
}
