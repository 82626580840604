import { toAbsoluteUrl } from '@helpers/index';
import SVG from 'react-inlinesvg';
import { LoginForm } from './LoginForm';
import { LoginWrapper } from './LoginWrapper';

export const Login = () => {
  const logoLink = window.location.host.includes('.de')
    ? 'https://hellobello.de'
    : 'https://hellobello.at';

  return (
    <LoginWrapper>
      <div className="login-logo">
        <a
          href={logoLink}
          target="_blank"
          className="w-[8rem] block mx-auto"
          rel="noopener noreferrer"
        >
          <SVG src={toAbsoluteUrl('/media/logos/logo-green.svg')} />
        </a>
      </div>
      <h1>
        <span className="login-headline">WILKOMMEN IN DEINEM</span>
        <span className="login-headline-sup">PFOTENPORTAL</span>
      </h1>

      <p className="login-text">
        Gib unten deine E-Mail Adresse ein, die du für den Kauf deines Frischfutters verwendet hast.
        Wir senden dir umgehend einen Link per E-Mail, mit dem du dich direkt anmelden kannst.
      </p>

      <LoginForm />

      <a href="mailto:support@hellobello.at" className="text-center text-light-600">
        Hast du Schwierigkeiten beim Einloggen? Kontaktiere unser Support-Team!
      </a>
    </LoginWrapper>
  );
};
