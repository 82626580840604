/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import clsx from 'clsx';
import { useLayout } from '../../core';
import { KTSVG } from '../../../helpers';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../helpers/AssetHelpers';
import SVG from 'react-inlinesvg';
import { AsideMenu } from './menu/AsideMenu';
import { AsideProfile } from './profile/AsideProfile';

const Aside: FC = () => {
  const { config, classes } = useLayout();
  const { aside } = config;

  return (
    <div
      id="kt_aside"
      className={clsx('aside card aside-wrapper-card text-sm md:text-xl', classes.aside.join(' '), {
        'd-none': !aside.display,
      })}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      <button className="svg-icon aside-close md:hidden" id="kt_aside_mobile_toggle">
        <SVG src={toAbsoluteUrl('/media/svg/aside/close.svg')} />
      </button>

      <div className="hidden mb-12 aside-header flex-col-auto lg:block">
        <Link to="/dashboard">
          <SVG src={toAbsoluteUrl('/media/logos/logo-green.svg')} />
        </Link>
      </div>

      {/* begin::Aside profile */}
      <AsideProfile />
      {/* end::Aside profile */}

      {/* begin::Aside menu */}
      <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      {/* end::Aside menu */}

      {/* begin::Footer */}
      {/* <div className='pt-5 aside-footer pb-7' id='kt_aside_footer'>

      </div> */}
      {/* end::Footer */}
    </div>
  );
};

export { Aside };
